import React from "react"
import { Link } from "gatsby"
import * as styles from "./bildunterschrift.module.css"
import "../layout/layout.css"
import { Container } from "react-bootstrap"

const Bildunterschrift = props => {
  return (
    <Container className={`d-none d-sm-flex ${styles.wrapper}`}>
      <h4>{props.bu}</h4>
    </Container>
  )
}

export default Bildunterschrift
