import React, { useLayoutEffect } from "react"
import { SwiperSlide } from "swiper/react"
import { Dropdown, ButtonGroup, Container } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SectionWrap from "../../components/sectionWrap"
import Introtext from "../../components/introtext"
import Bildunterschrift from "../../components/bildunterschrift"
import SwiperDefault from "../../components/swiperDefault"
import BigHeaderVideo from "../../components/header/bigHeader/bigHeaderVideo"
import KontaktFormDefault from "../../components/kontakt/kontaktFormDefault"
import myLocalVid from '../../images/projekte/Projekte_Header_v3_komp.mp4'
import WhatsNext from "../../components/whatsNext/whatsnext"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as projekteStyles from "./projekte.module.css"

const ProjekteIndex = function (props, ref) {

  const data = useStaticQuery(graphql`
    query ProjekteQuery {
      allProjekteJson(filter: { scope: { eq: "hero" } }) {
        edges {
          node {
            desc
            id
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
            linktitle
            logo
            title
            type
            scope
          }
        }
      }

      ogimage: file(relativePath: { eq: "images/projekte-og.jpg" }) {
        childImageSharp {
            fixed(height: 400) {
                src
                height
                width
            }
        }
      }      

      b2bplattforms01: file(
        relativePath: { eq: "images/projekte-b2b-plattforms-partnerwelt.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            )
        }
      }
      b2bplattforms02: file(
        relativePath: { eq: "images/projekte-b2b-plattforms-excellence.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      b2bplattforms03: file(
        relativePath: { eq: "images/projekte-b2b-plattforms-congstar.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }

      configurators01: file(
        relativePath: {
          eq: "images/projekte-konfiguratoren-busch-jaeger-smart-home-konfigurator.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      configurators02: file(
        relativePath: {
          eq: "images/projekte-konfiguratoren-abb-welcome-m-configurator.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      configurators03: file(
        relativePath: {
          eq: "images/bje-baustellenplaner-projekte.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }      
      configurators04: file(
        relativePath: {
          eq: "images/telekom-glasfaser-infopagebuilder-projekte.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }        

      salesapps01: file(
        relativePath: { eq: "images/projekte-salesapps-arag-beratungsapp.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      salesapps02: file(
        relativePath: {
          eq: "images/projekte-salesapps-busch-jaeger-playbook.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      salesapps03: file(
        relativePath: {
          eq: "images/projekte/bje-smarter-gallery/landing/bje-smarter-gallery-cat-slider.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }      

      marketingtools01: file(
        relativePath: {
          eq: "images/projekte-marketingtools-busch-jaeger-welcome-ip-simulator.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      marketingtools02: file(
        relativePath: {
          eq: "images/projekte-marketingtools-busch-jaeger-dab-simulator.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      marketingtools03: file(
        relativePath: {
          eq: "images/projekte-marketingtools-busch-jaeger-3dliveview.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      marketingtools04: file(
        relativePath: {
          eq: "images/projekte/abb-digital-factory-tours/dft.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }      

      ecosystems01: file(
        relativePath: { eq: "images/projekte/bje-mybuschjaeger/landing/bje-mybuschjaeger-quadrat.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }

      ecosystems02: file(
        relativePath: { eq: "images/projekte/abb-mybuildings/landing/abb-be.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }      

      websites01: file(
        relativePath: { eq: "images/projekte-websites-medl.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      websites02: file(
        relativePath: { eq: "images/projekte-websites-oryx.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      websites03: file(
        relativePath: { eq: "images/projekte-websites-mh025.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      websites04: file(
        relativePath: { eq: "images/projekte-websites-mhseniorendienste.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      websites05: file(
        relativePath: { eq: "images/projekte-websites-kuhmichel.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      corporateCommunications1: file(
        relativePath: { eq: "images/projekte/wsg-gb-2021/wsg-gb-2021-start.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      corporateCommunications2: file(
        relativePath: { eq: "images/projekte-cc-imagefilm-wsg.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      corporateCommunications3: file(
        relativePath: { eq: "images/projekte/vbw-gb-2021/vbw-gb-2021-start.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }     
      corporateCommunications4: file(
        relativePath: { eq: "images/projekte/telekom-glasfaservermarktung/telekom-glasfaservermarktung-projekte.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      corporateCommunications5: file(
        relativePath: { eq: "images/projekte/cp-katalog/cp-katalog-projekte.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }      

      orangeback01: file(relativePath: { eq: "images/orangeback-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const datanode = data.allProjekteJson.edges[0].node

  const projekteData = [
    {
      category: "B2B platforms",
      id: "b2b-platforms",
      customers: ["Buderus", "Busch-Jaeger", "congstar"],
      description: "Innovative Kundenbindungs- und Vertriebspartnerprogramme.",
      slug: "/projekte/b2b-platforms",
      projects: [
        { name: "Buderus Partnerwelt", slug: "/projekte/b2b-platforms/buderus-partnerwelt", imgSrc: data.b2bplattforms01.childImageSharp.gatsbyImageData },
        { name: "Excellence Portal", slug: "/projekte/b2b-platforms/busch-jaeger-excellence", imgSrc: data.b2bplattforms02.childImageSharp.gatsbyImageData },
        { name: "congstar Partnernet", slug: "/projekte/b2b-platforms/congstar-partnernet", imgSrc: data.b2bplattforms03.childImageSharp.gatsbyImageData },
      ],
    },
    {
      category: "Konfiguratoren",
      id: "konfiguratoren",
      customers: ["ABB", "Busch-Jaeger", "telekom"],
      description: "Maßgeschneiderte Tools zur individuellen Konfiguration Ihrer Produkte.",
      slug: "/projekte/konfiguratoren",
      projects: [
        { name: "Busch-Jaeger Smart Home Konfigurator", slug: "/projekte/konfiguratoren/busch-jaeger-smart-home-konfigurator", imgSrc: data.configurators01.childImageSharp.gatsbyImageData },
        { name: "Telekom Glasfaser Infopage-Builder", slug: "/projekte/konfiguratoren/telekom-glasfaser-infopage-builder#", imgSrc: data.configurators04.childImageSharp.gatsbyImageData },
        { name: "Busch Baustellenplaner", slug: "/projekte/konfiguratoren/busch-jaeger-baustellenplaner", imgSrc: data.configurators03.childImageSharp.gatsbyImageData },
        { name: "ABB Welcome Konfigurator", slug: "/projekte/konfiguratoren/abb-welcome-konfigurator", imgSrc: data.configurators02.childImageSharp.gatsbyImageData },
      ],
    },
    {
      category: "Sales Apps & Tools",
      id: "sales-apps",
      customers: ["ARAG", "Busch-Jaeger"],
      description: "Digitale Gesprächsunterstützung für Ihren Außendienst.",
      slug: "/projekte/sales-apps",
      projects: [
        { name: "ARAG BeratungsApp", slug: "/projekte/sales-apps/arag-beratungsapp", imgSrc: data.salesapps01.childImageSharp.gatsbyImageData },
        { name: "Busch-Jaeger Playbook", slug: "/projekte/sales-apps/busch-jaeger-playbook", imgSrc: data.salesapps02 ? data.salesapps02.childImageSharp.gatsbyImageData : null },
        { name: "Busch-Jaeger Smarter Gallery", slug: "/projekte/sales-apps/smarter-gallery", imgSrc: data.salesapps03 ? data.salesapps03.childImageSharp.gatsbyImageData : null },
      ],
    },
    {
      category: "Virtuelle Produktpräsentationen",
      id: "virtuelle-produktpraesentationen",
      customers: ["ABB","Busch-Jaeger"],
      description: "Innovative Ideen zur virtuellen Präsentation Ihrer Produkte.",
      slug: "/projekte/virtuelle-produktpraesentationen",
      projects: [
        { name: "ABB Digital Factory Tours", slug: "/projekte/virtuelle-produktpraesentationen/digital-factory-tours", imgSrc: data.marketingtools04.childImageSharp.gatsbyImageData },
        { name: "Busch-Jaeger Simulation Türkommunikation", slug: "/projekte/virtuelle-produktpraesentationen/welcome-ip-simulator", imgSrc: data.marketingtools01.childImageSharp.gatsbyImageData },
        { name: "3D LiveView App für Busch-Jaeger", slug: "/projekte/virtuelle-produktpraesentationen/3d-liveview-app", imgSrc: data.marketingtools03.childImageSharp.gatsbyImageData },
        { name: "Busch-Jaeger DAB+ Radio Simulation", slug: "/projekte/virtuelle-produktpraesentationen/digitalradio-dab-plus-simulator", imgSrc: data.marketingtools02.childImageSharp.gatsbyImageData },
      ],
    },
    {
      category: "Ecosystems",
      id: "ecosystems",
      customers: ["ABB", "Busch-Jaeger"],
      description: "Strategien und Werkzeuge zur Vernetzung Ihrer Produkte und zur Entwicklung digitaler Mehrwerte.",
      slug: "/projekte/ecosystems",
      projects: [
        { name: "myBUSCH-JAEGER", slug: "/projekte/ecosystems/mybusch-jaeger-portal", imgSrc: data.ecosystems01.childImageSharp.gatsbyImageData },
        { name: "ABB MyBuildings", slug: "/projekte/ecosystems/abb-buildings-ecosystem", imgSrc: data.ecosystems02.childImageSharp.gatsbyImageData },
      ],
    },
    {
      category: "Websites",
      id: "websites",
      customers: ["medl", "kuhmichel", "oryx"],
      description: "Professioneller Webauftritt für Ihr Unternehmen.",
      slug: "/projekte/websites",
      projects: [
        { name: "medl.de", slug: "/projekte/websites/medl", imgSrc: data.websites01.childImageSharp.gatsbyImageData },
        { name: "Kuhmichel.com", slug: "/projekte/websites/kuhmichel", imgSrc: data.websites05.childImageSharp.gatsbyImageData },
        { name: "oryx.com", slug: "/projekte/websites/oryx", imgSrc: data.websites02.childImageSharp.gatsbyImageData },        
      ],
    },
    {
      category: "Corporate Communications",
      id: "corporate-communications",
      customers: ["telekom", "VBW Bochum", "cp", "WSG"],
      description: "Kreative Lösungen zur Erreichung Ihrer Kommunikationsziele.",
      slug: "/projekte/corporate-communications",
      projects: [
        { name: "Telekom | Glasfaser-Vermarktung", slug: "/projekte/corporate-communications/telekom-glasfaservermarktung", imgSrc: data.corporateCommunications4.childImageSharp.gatsbyImageData },
        { name: "wsg | Geschäftsbericht 2021", slug: "/projekte/corporate-communications/wsg-geschaeftsbericht-2021", imgSrc: data.corporateCommunications1.childImageSharp.gatsbyImageData },
        { name: "wsg | Strategiefilm 2030", slug: "/projekte/corporate-communications/wsg-strategiefilm", imgSrc: data.corporateCommunications2.childImageSharp.gatsbyImageData },
        { name: "VBW Bochum | Geschäftsbericht 2021", slug: "/projekte/corporate-communications/vbw-bochum-geschaeftsbericht-2021", imgSrc: data.corporateCommunications3.childImageSharp.gatsbyImageData },
        { name: "C + P | Der Katalog", slug: "/projekte/corporate-communications/cp-katalog", imgSrc: data.corporateCommunications5.childImageSharp.gatsbyImageData },
      ],
    },
  ]

 

  return (
    <Layout>
      <SEO
        title="Projekte"
        description="Projekte von Q:marketing"
        image={data?.ogimage?.childImageSharp?.fixed}
      />       

      <BigHeaderVideo mode="allatonce" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local">        
        <div className="row py-5 py-md-0">
          <div className="col-12 col-md-5">
            <h1>Projekte</h1>
            <p className="introtext">
            Wir überzeugen mit Kompetenz, Kreativität und Charakter. Wir realisieren eine Vielzahl unterschiedlicher Lösungen – von der App bis zur Website. Als Lead oder Partner in Ihrem Agenturnetzwerk.
            </p>
            <Dropdown
              as={ButtonGroup}
              size="lg"
              className="btn-block"
              block={true}
            >
              <AnchorLink
                to="/projekte#b2b-platforms"
                title="↓ Mehr Erfahren"
                className="q-btn q-btn-primary w-85"
              />
              <Dropdown.Toggle
                split
                className="q-btn q-btn-primary p-0"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu>
                <Dropdown.Item href="/projekte/b2b-platforms">
                  B2B platforms
                </Dropdown.Item>
                <Dropdown.Item href="/projekte/konfiguratoren">
                  Konfiguratoren
                </Dropdown.Item>
                <Dropdown.Item href="/projekte/sales-apps">
                  Sales Apps & Tools
                </Dropdown.Item>
                <Dropdown.Item href="/projekte/virtuelle-produktpraesentationen">
                  Virtuelle Produktpräsentationen
                </Dropdown.Item>
                <Dropdown.Item href="/projekte/ecosystems">
                  Ecosystems
                </Dropdown.Item>
                <Dropdown.Item href="/projekte/websites">
                  Websites
                </Dropdown.Item>
                <Dropdown.Item href="/projekte/corporate-communications">
                  Corporate Communications
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-7"></div>
        </div>        
      </BigHeaderVideo>
      

      <div className={`${projekteStyles.container}`}>
       
       {projekteData.map((project, idx) => (
          <SectionWrap
            id={project.id}
            className={`${projekteStyles.child}`}
            bgCol={"#fff"}
            height="fullHeight"
            pin={true}
            snap={true}
          >
          <Container fluid>
            <Row
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: (idx % 2 != 0) ? "row-reverse" : "row"
              }}
            >
              <Col xs={12} lg={6} className="p-0">
                  <SwiperDefault slidesPerView={1} navi={true}>
                {project.projects.map(projects => (
                    <SwiperSlide style={{width: "100%"}}>
                      <Link to={projects.slug} style={{display: "flex", justifyContent: "center"}}>
                        <Bildunterschrift bu={projects.name} />
                        <GatsbyImage
                          image={projects.imgSrc}
                          alt=""
                          placeholder="blurred"
                          className={projekteStyles.gatsbyimageFullheight}
                        />
                      </Link>
                    </SwiperSlide>
                ))}
                </SwiperDefault>
              </Col>
              <Col xs={12} lg={6} className="my-5 p-0">
                <Introtext
                  skypic={project.customers}
                  skyhead=""
                  titel={project.category}
                  text={project.description}
                  linklabel="Beispiele ansehen"
                  link={project.slug}
                />
              </Col>
            </Row>
            </Container>
          </SectionWrap>
        ))}                

        {/* <KontaktFormDefault /> */}

        <WhatsNext text="Agentur &rarr;" link="/agentur"/>
                
      </div>
    </Layout>
  )
}

export default ProjekteIndex